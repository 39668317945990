<template>
     <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" class="lg:mx-40 mx-7 rounded-2xl bg-blueNew1 lg:my-20 my-14 mt-10 lg:pb-4 pb-10">
     <div class="lg:w-auto bg-blueNew1 text-white lg:flex lg:justify-center lg:items-stretch lg:mt-0 w-80 min-h-[33rem] mt-12 mb-12 shadow-[0_2.5rem_2rem_#00000030] relative  rounded-3xl">
     <div class="text-2xl"> Politique de sécurité</div>   <br>
    
Les vulnérabilités des applications Web représentent la plus grande partie des vecteurs d’attaque en dehors des logiciels malveillants. Il est crucial que toute application Web soit évaluée pour les vulnérabilités et que toute vulnérabilité, soit corrigée avant le déploiement en production.
Le but de cette politique est de définir les évaluations de sécurité des applications Web au sein de KIPFUL. Les évaluations des applications Web sont effectuées pour identifier les faiblesses potentielles ou réalisées suite à une mauvaise configuration par inadvertance, une authentification faible, une gestion d’erreur insuffisante, une fuite d’information sensible, etc. La découverte et l’atténuation subséquente de ces problèmes limitera la surface d’attaque des services de KIPFUL disponible à l’interne et à l’externe ainsi que de satisfaire la conformité avec toutes les politiques pertinentes en place.<br/>

PORTÉE<br/>
Cette politique couvre toutes les évaluations de sécurité des applications Web demandées par tout individu, groupe ou département dans le but de maintenir la posture de sécurité, la conformité, la gestion des risques et le contrôle des changements des technologies utilisées à KIPFUL.
Toutes les évaluations de sécurité des applications Web seront effectuées par le personnel de sécurité délégué soit employé ou contracté par KIPFUL. Tous les résultats sont considérés comme confidentiels et doivent être distribués aux personnes sur une base de “besoin de savoir”. La distribution de n’importe quelles conclusions en dehors de KIPFUL est strictement interdite à moins qu’approuvée par le chef de la technologie.
Toute relation au sein des applications à plusieurs niveaux trouvée pendant la phase de cadrage sera incluse dans l’évaluation, à moins qu’elle ne soit explicitement limitée. Les limitations et les justifications ultérieures seront documentées avant le début de l’évaluation. <br/>

POLITIQUE DE SÉCURITÉ<br/>
1. Les applications Web sont soumises à des évaluations de sécurité fondées sur les critères suivants :
Version nouvelle ou majeure de l’application – sera soumise à une évaluation complète avant l’approbation de la documentation sur le contrôle des changements et/ou la diffusion dans l’environnement réel.
Application Web d’un tiers ou acquise – sera soumise à une évaluation complète, après quoi elle sera liée aux exigences de la politique.
Communiqués ponctuels – seront soumis à un niveau d’évaluation approprié en fonction du risque de modification de la fonctionnalité et/ou de l’architecture de l’application.
Les versions de correctifs – feront l’objet d’une évaluation appropriée en fonction du risque de modification de la fonctionnalité et/ou de l’architecture de l’application.

Communiqués d’urgence – Un communiqué d’urgence sera autorisé à renoncer aux évaluations de sécurité et à assumer le risque présumé jusqu’à ce qu’une évaluation appropriée puisse être effectuée. Les versions d’urgence seront désignées comme telles par le dirigeant principal de l’information ou un gestionnaire approprié à qui ce pouvoir a été délégué.<br/>
2. Tous les problèmes de sécurité qui sont découverts au cours des évaluations doivent être atténués en fonction des niveaux de risque suivants. Les niveaux de risque sont fondés sur la méthode d’évaluation des risques du PEMA. Des essais de validation des mesures correctives seront nécessaires pour valider les stratégies de correction et/ou d’atténuation de tout problème découvert de niveau de risque moyen ou supérieur.

• Élevé – Tout problème à risque élevé doit être corrigé immédiatement ou d’autres stratégies d’atténuation doivent être mises en place pour limiter l’exposition avant le déploiement. Les applications présentant des problèmes à risque élevé peuvent être mises hors ligne ou se voir refuser le déploiement dans l’environnement réel.
• Moyen – Les problèmes à risque moyen doivent être examinés afin de déterminer ce qui est nécessaire pour les atténuer et être programmés en conséquence. Les applications présentant des risques moyens peuvent être mises hors ligne ou refusées dans l’environnement réel en fonction du nombre de problèmes et si plusieurs problèmes augmentent le risque à un niveau inacceptable. Les problèmes devraient être réglés dans le cadre d’un correctif ou d’une libération ponctuelle, à moins que d’autres stratégies d’atténuation ne limitent l’exposition.
• Faible – Le problème devrait être examiné afin de déterminer ce qui est nécessaire pour le corriger et être programmé en conséquence.<br/>
3. Les niveaux d’évaluation de la sécurité suivants doivent être établis par l’organisation CONIX ou toute autre organisation désignée qui effectuera les évaluations.

• Complet – Une évaluation complète comprend des tests pour toutes les vulnérabilités connues des applications Web à l’aide d’outils automatisés et manuels basés sur le Guide de test du PSTAO. Une évaluation complète utilisera des techniques manuelles de tests de pénétration pour valider les vulnérabilités découvertes afin de déterminer le risque global de toute vulnérabilité découverte.
• Rapide – Une évaluation rapide consistera en un balayage (typiquement) automatisé d’une application pour les dix principaux risques de sécurité des applications Web de l’OWASP, au minimum.
• Ciblée – Une évaluation ciblée est effectuée pour vérifier les changements de correction des vulnérabilités ou les nouvelles fonctionnalités de l’application.<br/>
4. Les outils d’évaluation de la sécurité des applications Web actuellement approuvés qui seront utilisés pour les essais sont les suivants :

OWASP ZED ATTACK PROXY
SECURITY GUARDIAN
NMAP
NESSUS
CORE IMPACT
D’autres outils et/ou techniques peuvent être utilisés selon ce qui est trouvé dans l’évaluation des défauts et la nécessité de déterminer la validité et le risque sont soumis à la discrétion de l’équipe d’ingénierie de la sécurité.

RESPECT DES POLITIQUES

MESURE DE CONFORMITÉ
L’équipe KIPFUL vérifiera la conformité à la présente politique par diverses méthodes, y compris, mais sans s’y limiter, par des vérifications périodiques, la surveillance vidéo, des rapports sur les outils d’affaires, des vérifications internes et externes et la rétroaction au titulaire de la politique.

EXCEPTIONS
Toute exception à la politique doit être approuvée au préalable par l’équipe Infosec.

NON-COMPLIANCE
Un employé ayant enfreint la présente politique peut faire l’objet de mesures disciplinaires pouvant aller jusqu’au licenciement.

Les évaluations des applications Web sont une exigence du processus de contrôle des changements et doivent respecter la présente politique, à moins qu’il ne soit établi qu’elles sont exemptées. Toutes les versions des applications doivent passer par le processus de contrôle des changements. Toute application Web qui ne respecte pas la présente politique peut être mise hors ligne jusqu’à ce qu’une évaluation officielle puisse être effectuée, à la discrétion du dirigeant principal de l’information.

LES NORMES, POLITIQUES ET PROCESSUS CONNEXES

OWASP TOP TEN Project
OWASP Testing Guide
OWASP Mobile Security Testing Guide
Vulnerabilities Database
    </div>
  </div> 
</template>

<script>

export default {
  name: "index",

  components: {
    

    },
}

</script>
